<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="8" md="8">
      <v-card height="50vh" max-height="600px" width="80vw" style="overflow: hidden;">
        <v-row class="fill-height ma-0">
          <v-col cols="12" md="6" class="welcome white--text d-flex justify-center align-center">
            <h1>Welcome to Mango</h1>
          </v-col>

          <v-col cols="12" md="6" class="d-flex flex-column justify-center align-start pl-7">
            <img style="margin-bottom:20px;" src="../assets/mango-header-logo.png" />
            <p>
              Sign in with your connected Microsoft account.
              <br />
              Problems? Contact the IT-department.
            </p>

            <v-btn large color="secondary" @click="login()">
              <v-icon left dark>mdi-lock-outline</v-icon>
              Sign in with Microsoft
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Login",
  mounted() {
    this.$store.commit('auth/removeUser')
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("loading", true);
        await this.$store.dispatch("auth/login");
        await this.$router.replace("/orders/search");
      } catch (error) {
        await this.$store.dispatch("error", error.message);
      } finally {
        await this.$store.dispatch("loading", false);
      }
    }
  }
};
</script>

<style>
.welcome {
  background: rgb(250, 197, 87);
  background: linear-gradient(16deg, rgba(250, 197, 87, 1) 0%, rgba(243, 122, 98, 1) 100%);
}
</style>
