var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "8"
    }
  }, [_c('v-card', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "height": "50vh",
      "max-height": "600px",
      "width": "80vw"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0"
  }, [_c('v-col', {
    staticClass: "welcome white--text d-flex justify-center align-center",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h1', [_vm._v("Welcome to Mango")])]), _c('v-col', {
    staticClass: "d-flex flex-column justify-center align-start pl-7",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": require("../assets/mango-header-logo.png")
    }
  }), _c('p', [_vm._v(" Sign in with your connected Microsoft account. "), _c('br'), _vm._v(" Problems? Contact the IT-department. ")]), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.login();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("mdi-lock-outline")]), _vm._v(" Sign in with Microsoft ")], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }